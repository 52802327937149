nav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    box-shadow: 0 0 10px rgba(0,0,0,.09);
    background-color: rgb(255, 255, 255);
    position: fixed;
    width: 100%;
    z-index: 100;
    font-weight: 700;

    .nav-container {
        width: 100%;
        max-width: 1280px;
        position: absolute;
        // top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1000;
        box-sizing: border-box;
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        // background-color: blue;
    }

    .home-logo {
        font-size: 25px;
        pointer-events: none;
        user-select: none;
    }

    ul  {
        list-style-type: none;
        display: flex;
        gap: 75px;
        font-size: 18px;
        font-weight: 500;
    }

    ul li {
        cursor: pointer;
        transition: .4s;
    }

    .nav-menu li:hover {
        color: orange;
    }

    .hamburger {
            display: none;
    }

    @media (width < 750px) {
        z-index: 1000;

        ul {
            display: flex;
            flex-direction: column;
            padding: 0;
            height: 100%;
            margin: 80px 0;
            color: white;
        }

        .logo {
            font-size: 32px;
        }

        .hamburger {
            display: block;
            cursor: pointer;
            z-index: 999;
            // color: yellow;
            font-size: 30px;
            transition: .1s;
        }

        .hamburger:hover {
            color: orange !important;
        }

        .nav-menu {
            position: absolute;
            left: -100%;
            top: 48px;
            display: flex;
            flex-direction: column;
            background-color: rgba(0,0,0,.97);
            // background-color: red;
            width: 100%;
            height: 100vh;
            text-align: center;
            transition: .3s;
            // padding-top: 100px;
        }
    
        .nav-menu.active {
            left: 0;
            // padding: 100px 0;
        }
    }
}