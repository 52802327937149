body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.body-container {
  display: grid;
}

h1 {
  margin: 0;
}

body {
  margin: 0;
  background-color: #f4f4f4;
}

p {
  margin: 0;
  padding: 0;
}

section {
  display: grid;
}

.container {
  margin: 200px 0;
}

.title {
  font-weight: bold;
  font-size: 30px;
  color: orange;
  margin-bottom: 20px;
}

.primary-text {
  font-size: 20px;
  font-weight: 800;
}

.secondary-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.4;
  color: rgb(79, 79, 79);
}

a:link,
a:visited {
  text-decoration: none;
  color: black;
}